/**
 * Creates a click handler for the `/consent` link in the footer that will
 * resurface the Sourcepoint privacy manager modal.
 */
const setupHandlersForConsentLink = () => {
  const consentLinks = document.querySelectorAll<HTMLElement>(
    /**
     * This selector is intentionally specific.
     *
     * `[href*="/consent"]` Searches for elements with the href
     * attribute that ends with `/consent`
     *
     * `span[data-gdpr-implicit-consent-blacklist="true"]` searches for
     * and child elements that contain the attribute `data-gdpr-implicit-consent-blacklist`
     * set to true. This is because we want to only target the gdpr specific consent link.
     */
    '[href*="/consent"]',
  );

  consentLinks.forEach((consentLink) => {
    consentLink.removeAttribute('href');
    consentLink.setAttribute('role', 'button');
    consentLink.setAttribute('tabindex', '0');
    consentLink.setAttribute('aria-label', 'Open consent preferences');

    const openModal = (event: Event) => {
      event.preventDefault();
      if (typeof window?._sp_?.gdpr?.loadPrivacyManagerModal === 'function') {
        window._sp_.gdpr.loadPrivacyManagerModal();
      }
    };

    consentLink.addEventListener('click', openModal);

    consentLink.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        openModal(event);
      }
    });
  });
};

export default setupHandlersForConsentLink;
