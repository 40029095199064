/**
 * Function to open the consent page if the `?openPrivacyManagerModal` query param
 * is provided.
 */
const openPrivacyManagerModal = () => {
  if (
    window.location.search.includes('openPrivacyManagerModal') &&
    typeof window._sp_?.gdpr?.loadPrivacyManagerModal === 'function'
  ) {
    window._sp_.gdpr.loadPrivacyManagerModal(window._sp_ss_config.privacyManagerModalId);
  }
};

export default openPrivacyManagerModal;
