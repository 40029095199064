import { CMP_UI_SHOWN, TCF_API_VERSION } from './constants';
import getConsentState from './getConsentState';

const waitForTCData = (): Promise<void> => {
  return new Promise((resolve) => {
    window.__tcfapi('addEventListener', TCF_API_VERSION, (TCData: TCData, success: boolean) => {
      const INTERVAL = 500;

      /* 
        If CMP consent dialog is shown, we should not resolve this promise. It should only be resolved if user either accept/reject consent.
        Refer this link to know more aabout event statushttps://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#addeventlistener

      */
      if (success && TCData.eventStatus !== CMP_UI_SHOWN) {
        window.__tcfapi('removeEventListener', TCF_API_VERSION, () => {}, TCData.listenerId);
        window._sp_lib.TCData = TCData;

        /**
         * 1. We need to load the banner library even if user rejected all
         * consent. GAM (Google Ad Manager) will be able to serve consentless
         * banners with TAD (Technical Ad Delivery) feature after rolling out
         * TCF 2.2
         * 2. Promise should not be resolved until  onConsentReady
         */
        const wait = setInterval(() => {
          if (getConsentState(document.cookie)) {
            resolve();
            clearInterval(wait);
          }
        }, INTERVAL);
      }
    });
  });
};

export default waitForTCData;
