import isbot from 'isbot';

const extraBots = ['Google-Read-Aloud'];

isbot.extend(extraBots);

export default function isBot(userAgent: string = window.navigator.userAgent): boolean {
  try {
    return userAgent ? isbot(userAgent) : false;
  } catch (e) {
    return true;
  }
}
