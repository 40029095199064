import Google from './Google';
import AdobeAudienceManager from './AdobeAudienceManager';
import Facebook from './Facebook';
import Criteo from './Criteo';
import GoogleAnalytics from './GoogleAnalytics';
import Qualaroo from './Qualaroo';
const consentTo = {
  AdobeAudienceManager,
  Google,
  Facebook,
  Criteo,
  GoogleAnalytics,
  Qualaroo,
} as const;

export default consentTo;
