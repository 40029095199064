import { w } from './global';

export enum EVENT_CATEGORY {
  CONSENT_BANNER = 'ConsentBanner',
}

export enum EVENT_ACTION {
  CONSENT_BANNER_REQUESTED = 'ConsentBannerRequested',
  CONSENT_BANNER_LOADED = 'ConsentBannerLoaded',
  CONSENT_GRANTED = 'ConsentGranted',
  CONSENT_DENIED = 'ConsentDenied',
  SOURCEPOINT_LIB_ERROR = 'SourcepointLibError',
}
export enum EVENT_LABEL {
  LAYER_ONE = 'layer=1',
  LAYER_TWO = 'layer=2',
  SCOPE_ALL = 'scope=all',
  SCOPE_PARTIAL = 'scope=partial',
}

export enum CHOICE_TYPE {
  ACCEPT_ALL = 11,
  SETTINGS = 12,
  REJECT_ALL = 13,
}

export enum PURPOSE_CONSENT {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all',
}

export enum VENDOR_CONSENT {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all',
}

export const decorateEventObject = (action: String, labels: Array<String> = []): TAnalyticsEvent => {
  return {
    eventAction: action,
    eventLabel: labels.join(';'),
  };
};

export function logAnalyticsEvent(eventAction: string, eventLabels: String[]) {
  const event = decorateEventObject(eventAction, eventLabels);
  const { ecGa, ga, ecGaEventsOnLoad = [] } = w;
  if (ecGa && ga) {
    ecGa('trackEvent', event);
  } else {
    ecGaEventsOnLoad.push(event);
  }
}

export const logConsentBannerLoaded = (labels: String[]) => {
  logAnalyticsEvent(EVENT_ACTION.CONSENT_BANNER_LOADED, labels);
};

export const logConsentGranted = (labels: String[]) => {
  logAnalyticsEvent(EVENT_ACTION.CONSENT_GRANTED, labels);
};

export const logConsentDenied = (labels: String[]) => {
  logAnalyticsEvent(EVENT_ACTION.CONSENT_DENIED, labels);
};

export const logSourcepointLibError = (labels: String[]) => {
  logAnalyticsEvent(EVENT_ACTION.SOURCEPOINT_LIB_ERROR, labels);
};
