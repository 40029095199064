import { MP_COOKIE_NAME } from './constants';
import consentTo from './consentTo';

const cookieRe = new RegExp(`(^| )${MP_COOKIE_NAME}=([^;]*)`);

const getConsentState = (cookie: string): TConsentState => {
  const mpConsentStateString = typeof cookie === 'string' ? cookie.match(cookieRe)?.[2] : '';

  if (!mpConsentStateString) {
    return {};
  }

  const consentArray = mpConsentStateString.split(',');
  return Object.keys(consentTo).reduce<TConsentState>((prev, curr) => {
    if (consentArray.includes(curr)) {
      return {
        ...prev,
        [curr]: true,
      };
    }
    return prev;
  }, {});
};

export default getConsentState;
