import consentTo from './consentTo';
import { MP_COOKIE_NAME, TCF_API_VERSION } from './constants';

const getVendorsConsent = (win: Window): Promise<TSourcePointGetCustomVendorsConsentData> => {
  return new Promise((resolve, reject) => {
    win.__tcfapi(
      'getCustomVendorConsents',
      TCF_API_VERSION,
      function (data: TSourcePointGetCustomVendorsConsentData, success: boolean) {
        if (success) {
          resolve(data);
          return;
        }
        // TODO: track this in analytics
        reject(new Error('load of custom vendors failed'));
      },
    );
  });
};

const extractConsentString = async (win: Window): Promise<string> => {
  const data = await getVendorsConsent(win);
  const consents: string[] = [];
  Object.entries(consentTo).forEach(([vendorName, isConsented]) => {
    if (isConsented(data.grants)) {
      consents.push(vendorName);
    }
  });
  return consents.join(',');
};

const extractPageDomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  // handle localhost
  if (parts.length === 1) {
    return hostname;
  }
  return ['', ...parts.slice(-2)].join('.');
};

const MAX_AGE = 31536000; // one year (seconds in a year)

const saveCookie = async () => {
  document.cookie = `${MP_COOKIE_NAME}=${await extractConsentString(
    window,
  )}; max-age=${MAX_AGE}; domain=${extractPageDomain()}`;
};

export default saveCookie;
